<template>
    <v-select
        v-model="selected"
        :class="classObject"
        :options="vacunas"
        :reduce="(vacuna) => (mode === 'object' ? vacuna : vacuna.id)
    "
        label="text"
        @input="setChange"
        @search="debounceSearch"
    >
        <template #no-options="{ search }">
            No se encontraron resultados para <strong>{{ search }}</strong>
        </template>
        <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events"/>
        </template>
    </v-select>
</template>

<script>
import vacunaService from "../../services/vacunaService";
import {isEmpty, debounce} from 'lodash';
import VSelect from 'vue-select';

export default {
    props: ["value", "mode", "validator"],
    components : {VSelect},
    data() {
        return {
            vacunas: [],
            selected: this.value,
            search: "",
        };
    },
    created() {
        this.debounceSearch = debounce(this.buscarVacunas, 300);
    },
    watch: {
        value: async function (value) {
            if (this.mode === "object") {
                if (isEmpty(value) || value === undefined || value === null) {
                    this.selected = this.mode === "object" ? {} : "";
                    return;
                }
            } else {
                if (value === "" || value === undefined || value === null) {
                    this.selected = this.mode === "object" ? {} : "";
                    return;
                }
            }

            let id = this.mode === "object" ? value.id : value;

            if (isEmpty(this.vacunas.find((x) => x.id === id))) {

                let response = await vacunaService.show(id);

                this.vacunas.push({
                    id: response.data.id,
                    text: `${response.data.cups} ${response.data.nombre}`,
                });

                this.selected = {
                    ...response.data,
                    text: `${response.data.cups} ${response.data.nombre}`,
                };

            }else{
                this.selected = value;
            }

            this.$emit("input", this.selected);
        },
    },

    methods: {
        async buscarVacunas(search, loading) {
            try {
                if (search === "") {
                    return;
                }
                loading(true);

                let response = await vacunaService.search(search);
                this.vacunas = response.data.results;

                loading(false);
            } catch (e) {
                loading(false);
                alert("Ocurrio un error, por favor intente nuevamente");
                console.error(e);
            }
        },
        setChange(value) {
            this.$emit("input", value);
        },
    },
    computed: {
        classObject: function () {
            if (this.validator === undefined || this.validator === null) {
                return "";
            }
            return this.validator.$error ? "v-select-error is-invalid" : "is-valid";
        },
    },
};
</script>
<style scoped>
</style>
