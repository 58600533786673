<template>
  <main>
    <page-header-compact>
            Ingreso de Vacunas
    </page-header-compact>
    <div class="container-fluid mt-n10">
        <div class="row">
            <div class="col-12">
                <div class="card shadow mb-3">
                    <div
                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
                    >
                        <h6 class="font-weight-bold card-title m-0">
                            <i class="fas fa-truck-loading"></i> Datos
                            Basicos
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-8">
                                <label class="form-label">
                                    Sede
                                </label>
                                <VSelectSede v-model="sede" :mode="'object'"  :class="{'is-error' : $v.sede.$error}" ></VSelectSede>
                                <span v-if="!$v.sede.required && $v.sede.$dirty" class="text-danger">
                                    <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                                </span>
                            </div>
                            <div class="form-group col-md-4">
                                <label
                                    for="fecha_income"
                                    class="form-label"
                                >
                                    F. Ingreso
                                </label>
                                <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    name="fecha_income"
                                    id="fecha_income"
                                    :class="{
                                        'is-invalid' : $v.fecha.$error && $v.fecha.$dirty
                                    }"
                                    v-model="fecha"
                                />
                                <span class="invalid-feedback" v-if="!$v.fecha.required">
                                    Este campo es requerido
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card shadow mb-3">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="font-weight-bold card-title m-0">
                            <i class="fas fa-truck-loading"></i>
                            Cargar
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label class="form-label">Vacuna</label>
                                <VSelectVacuna v-model="income.vacuna" :mode="'object'"  :class="{'is-error' : $v.income.vacuna.$error}" ></VSelectVacuna>
                                <span v-if="!$v.income.vacuna.required && $v.income.vacuna.$dirty" class="text-danger">
                                    <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                                </span>
                            </div>
                            <div class="form-group col-md-4">
                                <label class="form-label" >Cantidad</label>
                                <input type="number" class="form-control form-control-sm"
                                    :class="{
                                        'is-invalid' : $v.income.cantidad.$error && $v.income.cantidad.$dirty
                                    }"
                                    v-model="income.cantidad"
                                />
                            </div>
                            <div class="form-group col-3">
                                <label class="form-label">Lote</label>
                                <input type="text"  class="form-control form-control-sm" v-model="income.lote"
                                />
                            </div>
                            <div class="form-group col-3">
                                <label class="form-label">F.Vencimiento</label>
                                <input type="date" v-model="income.fecha_vencimiento" class="form-control form-control-sm"
                                    :class="{
                                        'is-invalid' : $v.income.fecha_vencimiento.$error && $v.income.fecha_vencimiento.$dirty
                                    }"
                                />
                            </div>
                            <div class="form-group">
                                <button class="btn btn-primary btn-sm shadow-sm mt-4"  type="button" @click="addItem();"  >
                                    <i class="fas fa-plus text-white-50"></i> Agregar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card shadow">
                    <div
                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
                    >
                        <h6 class="font-weight-bold card-title m-0">
                            <i class="fas fa-list"></i> Detalles
                        </h6>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-sm" style="font-size: 14px">
                            <thead>
                            <tr>
                                <th>Vacuna</th>
                                <th>Fecha vencimiento</th>
                                <th>Lote</th>
                                <th>Cantidad</th>
                                <th colspan="2"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td>{{item.vacuna.text}}</td>
                                <td>{{ item.fecha_vencimiento }}</td>
                                <td>{{ item.lote }}</td>
                                <td >
                                    {{ item.cantidad }}
                                </td>
                                <td>
                                    <button
                                        class="btn btn-danger btn-sm shadow-sm"
                                        title="Eliminar"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        @click="removerItem(index)"
                                    >
                                        <i class="fas fa-trash text-white-50 fa-fw"></i>
                                    </button>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-success shadow-sm mt-2" @click="guardar();" >
                            <i class="fas fa-save text-white-50 fa-fw"></i> Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </main>
</template>
<script>
import Swal from 'sweetalert2';
import {required} from "vuelidate/lib/validators";
import VSelectSede from '../../../components/common/vSelectSede';
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import VSelectVacuna from "../../../components/common/VSelectVacuna";
import {isEmpty} from "lodash";
import ingresoVacunaService from '../../../services/ingresoVacunaService';
export default {
    components: {
        VSelectSede,PageHeaderCompact,VSelectVacuna
    },
    data() {
        return {
            sede: {},
            items: [],
            income : {
                vacuna : {},
                lote : '',
                fecha_vencimiento : '',
                cantidad : '',
            },
            fecha: '',
        }
    },
    validations (){
        return {
            sede:{required},
            fecha:{required},
            income : {
                vacuna : {required},
                cantidad : {required},
                fecha_vencimiento : {required},
            },
        }
    },
    methods: {
        addItem(){
             this.$v.income.$touch();

            if(this.$v.income.$invalid) return;
            this.items.push({...this.income});
        },
        async guardar() {

            try {
                this.$v.fecha.$touch();
                this.$v.sede.$touch();

                if(this.$v.fecha.$invalid) return;
                if(this.$v.sede.$invalid) return;

                if(isEmpty(this.items)) {
                    Swal.fire("Ups","Debe ingresar por lo menos una vacuna","warning");
                    return;
                }

                let ingreso_vacuna={
                    fecha: this.fecha,
                    sede:this.sede,
                    detalles:this.items
                }

                this.LoaderSpinnerShow();
                await ingresoVacunaService.store(ingreso_vacuna);
                this.LoaderSpinnerHide();
                
                Swal.fire("Exito","Datos guardados con exito","success");
                this.limpiarCampos();
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();
                
                Swal.fire("Ups!","ha ocurrido un error al procesar la solicitud","error");
            }
        },
        removerItem(index){
            this.items.splice(index, 1);
        },
        limpiarCampos(){
            this.sede={};
            this.fecha='';
            this.income.vacuna={};
            this.income.fecha_vencimiento='';
            this.income.cantidad='';
            this.income.lote='';
            this.items=[];
        }
    },
}

</script>
