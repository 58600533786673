var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('page-header-compact',[_vm._v(" Ingreso de Vacunas ")]),_c('div',{staticClass:"container-fluid mt-n10"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card shadow mb-3"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-8"},[_c('label',{staticClass:"form-label"},[_vm._v(" Sede ")]),_c('VSelectSede',{class:{'is-error' : _vm.$v.sede.$error},attrs:{"mode":'object'},model:{value:(_vm.sede),callback:function ($$v) {_vm.sede=$$v},expression:"sede"}}),(!_vm.$v.sede.required && _vm.$v.sede.$dirty)?_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" Este campo es requerido. ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{staticClass:"form-label",attrs:{"for":"fecha_income"}},[_vm._v(" F. Ingreso ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fecha),expression:"fecha"}],staticClass:"form-control form-control-sm",class:{
                                      'is-invalid' : _vm.$v.fecha.$error && _vm.$v.fecha.$dirty
                                  },attrs:{"type":"date","name":"fecha_income","id":"fecha_income"},domProps:{"value":(_vm.fecha)},on:{"input":function($event){if($event.target.composing)return;_vm.fecha=$event.target.value}}}),(!_vm.$v.fecha.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Este campo es requerido ")]):_vm._e()])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card shadow mb-3"},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Vacuna")]),_c('VSelectVacuna',{class:{'is-error' : _vm.$v.income.vacuna.$error},attrs:{"mode":'object'},model:{value:(_vm.income.vacuna),callback:function ($$v) {_vm.$set(_vm.income, "vacuna", $$v)},expression:"income.vacuna"}}),(!_vm.$v.income.vacuna.required && _vm.$v.income.vacuna.$dirty)?_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" Este campo es requerido. ")]):_vm._e()],1),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{staticClass:"form-label"},[_vm._v("Cantidad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.income.cantidad),expression:"income.cantidad"}],staticClass:"form-control form-control-sm",class:{
                                      'is-invalid' : _vm.$v.income.cantidad.$error && _vm.$v.income.cantidad.$dirty
                                  },attrs:{"type":"number"},domProps:{"value":(_vm.income.cantidad)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.income, "cantidad", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-3"},[_c('label',{staticClass:"form-label"},[_vm._v("Lote")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.income.lote),expression:"income.lote"}],staticClass:"form-control form-control-sm",attrs:{"type":"text"},domProps:{"value":(_vm.income.lote)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.income, "lote", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-3"},[_c('label',{staticClass:"form-label"},[_vm._v("F.Vencimiento")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.income.fecha_vencimiento),expression:"income.fecha_vencimiento"}],staticClass:"form-control form-control-sm",class:{
                                      'is-invalid' : _vm.$v.income.fecha_vencimiento.$error && _vm.$v.income.fecha_vencimiento.$dirty
                                  },attrs:{"type":"date"},domProps:{"value":(_vm.income.fecha_vencimiento)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.income, "fecha_vencimiento", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary btn-sm shadow-sm mt-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.addItem();}}},[_c('i',{staticClass:"fas fa-plus text-white-50"}),_vm._v(" Agregar ")])])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card shadow"},[_vm._m(2),_c('div',{staticClass:"card-body table-responsive"},[_c('table',{staticClass:"table table-sm",staticStyle:{"font-size":"14px"}},[_vm._m(3),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.vacuna.text))]),_c('td',[_vm._v(_vm._s(item.fecha_vencimiento))]),_c('td',[_vm._v(_vm._s(item.lote))]),_c('td',[_vm._v(" "+_vm._s(item.cantidad)+" ")]),_c('td',[_c('button',{staticClass:"btn btn-danger btn-sm shadow-sm",attrs:{"title":"Eliminar","data-toggle":"tooltip","data-placement":"top"},on:{"click":function($event){return _vm.removerItem(index)}}},[_c('i',{staticClass:"fas fa-trash text-white-50 fa-fw"})])]),_c('td')])}),0)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-success shadow-sm mt-2",on:{"click":function($event){return _vm.guardar();}}},[_c('i',{staticClass:"fas fa-save text-white-50 fa-fw"}),_vm._v(" Guardar ")])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center justify-content-between"},[_c('h6',{staticClass:"font-weight-bold card-title m-0"},[_c('i',{staticClass:"fas fa-truck-loading"}),_vm._v(" Datos Basicos ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center justify-content-between"},[_c('h6',{staticClass:"font-weight-bold card-title m-0"},[_c('i',{staticClass:"fas fa-truck-loading"}),_vm._v(" Cargar ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center justify-content-between"},[_c('h6',{staticClass:"font-weight-bold card-title m-0"},[_c('i',{staticClass:"fas fa-list"}),_vm._v(" Detalles ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Vacuna")]),_c('th',[_vm._v("Fecha vencimiento")]),_c('th',[_vm._v("Lote")]),_c('th',[_vm._v("Cantidad")]),_c('th',{attrs:{"colspan":"2"}})])])
}]

export { render, staticRenderFns }